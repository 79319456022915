<template>
  <div class="index">
    <embed style="width: 100%;height: 100%;" :src="src" />
    <div class="fixed">
      <van-button style="width: 45%;" block type="default">转发</van-button>
      <van-button style="width: 45%;" block type="info" color="#294ba3">分享到朋友圈</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: ''
    }
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  },
  created() {
    if(this.$route.query.url){
      this.src = this.$route.query.url
    }
  }
}
</script>

<style lang="less" scoped>
.fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f6f7fa;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .title-serch{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .bet{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    background: #f2f4f8;
    height: 92%;
    margin-top: -15px;

  }
}
</style>
