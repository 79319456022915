<template>
  <div class="index">
    <div class="title-serch">
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="搜索素材名称"
          shape="round"
          @search="onSearch"
      >
        <template #action>
          <div @click="shows = true" style="    display: flex;
    align-items: center; color:#2b60dd;">
            <img style="width: 24px;height: 24px;" src="../../assets/images/add-plue.svg"/>
          </div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <van-tabs v-model="active" style="margin-top: 10px;" swipe-threshold="7" color="#1890ff"
                title-active-color="#1890ff">
        <van-tab title="文字">
          <van-dropdown-menu active-color="#1890ff">
            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>
            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
          </van-dropdown-menu>
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.text.length > 0">
              <div v-for="(i,is) in listData.text" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <div class="share">
                    <div>
                      {{ i.content.content }}
                    </div>
                    <div>
                      <span>转发 {{ i.content.sendNum || 0 }}</span>
                      <!--                      <span>查看 </span>-->
                    </div>
                  </div>
                  <div style="align-self: flex-end;" @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                </div>

              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="图片">
          <van-dropdown-menu active-color="#1890ff">
            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>
            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
          </van-dropdown-menu>
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.img.length > 0">
              <div v-for="(i,is) in listData.img" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      :src="i.content.imageFullPath"/>
                  <div class="share">
                    <div>
                      {{ i.content.fileTitle }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum }}</span>
                      <!--                      <span>查看 0</span>-->
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i, shareData.title = i.content.title,shareData.url = i.content.imageFullPath">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="图文">
          <van-dropdown-menu active-color="#1890ff">
            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>
            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
          </van-dropdown-menu>
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.imgText.length > 0">
              <div v-for="(i,is) in listData.imgText" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      :src="i.content.imageFullPath"/>
                  <div class="share">
                    <div>
                      {{ i.content.TextTitle }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum }}</span>
                      <!--                      <span>查看 0</span>-->
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i, shareData.title = i.content.title,shareData.url = i.content.imageFullPath">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="音频">
          <van-dropdown-menu active-color="#1890ff">
            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>
            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
          </van-dropdown-menu>
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.vocie.length > 0">
              <div v-for="(i,is) in listData.vocie" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      style="width: 45px;height: 45px;"
                      src="../../assets/images/vocieType.png"/>
                  <div class="share">
                    <div>
                      {{ i.content.fileTitle }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum }}</span>
                      <!--                      <span>查看 0</span>-->
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i, shareData.title = i.content.title,shareData.url = i.content.voiceFullPath">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="视频">
          <van-dropdown-menu active-color="#1890ff">
            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>
            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
          </van-dropdown-menu>
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.video.length > 0">
              <div v-for="(i,is) in listData.video" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      style="width: 45px;height: 45px;"

                      src="../../assets/images/vidoeType.png"/>
                  <div class="share">
                    <div>
                      {{ i.content.fileTitle }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum }}</span>
                      <!--                      <span>查看 0</span>-->
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i, shareData.title = i.content.title,shareData.url = i.content.videoFullPath">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="小程序">
          <van-dropdown-menu active-color="#1890ff">
            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>
            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
          </van-dropdown-menu>
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.imgText.length > 0">
              <div v-for="(i,is) in listData.imgText" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      :src="i.content.imageFullPath"/>
                  <div class="share">
                    <div>
                      {{ i.content.TextTitle }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum }}</span>
                      <!--                      <span>查看 0</span>-->
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i, shareData.title = i.content.title,shareData.url = i.content.imageFullPath">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
        <van-tab title="文件">
          <van-dropdown-menu active-color="#1890ff">
            <van-dropdown-item @change="getList" v-model="value1" :options="option1"/>
            <van-dropdown-item @change="getList" v-model="value3" :options="option3"/>
            <van-dropdown-item @change="getList" v-model="value2" :options="option2"/>
          </van-dropdown-menu>
          <div class="item">
            <!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
            <!--              <van-list-->
            <!--                  style="height: 100%;"-->
            <!--                  v-model="loading"-->
            <!--                  :finished="finished"-->
            <!--                  finished-text="没有更多了"-->
            <!--                  @load="onLoad"-->
            <!--              >-->
            <div v-if="listData.file.length > 0">
              <div v-for="(i,is) in listData.file" @click="details(i)" :key="is" class="items">
                <div class="title">
                  <img
                      style="width: 45px;height: 45px;"

                      src="../../assets/images/fileType.png"/>
                  <div class="share">
                    <div>
                      {{ i.content.fileTitle }}
                    </div>
                    <div>
                      <span>转发 {{ i.sendNum }}</span>
                      <!--                      <span>查看 0</span>-->
                    </div>
                  </div>
                </div>
                <div class="share-button">
                  <div @click.stop="editShow = true,fileId = i.id">
                    <van-icon name="more-o"/>
                    更多
                  </div>
                  <div
                      @click.stop="shareShow = true,shareDataFile= i, shareData.title = i.content.title,shareData.url = i.content.fileFullPath">
                    <img src="../../assets/images/icon-share.svg"/>
                    分享
                  </div>
                </div>
              </div>
            </div>
            <!--              </van-list>-->
            <!--            </van-pull-refresh>-->
            <van-empty
                v-else
                class="custom-image"
                :image="require('../../assets/images/emptyimg.png')"
                description="暂无数据"
            />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!--  新建  -->
    <van-action-sheet
        v-model="shows"
        cancel-text="取消"
        description="选择素材类型"
        close-on-click-action
    >
      <template slot="default">
        <div @click="onSelect(item)" v-for="(item,index) in actions" :key="index"
             class="checkMarkting van-hairline--bottom">
          <div class="info">
            <p class="fs17 title">{{ item.name }}</p>
          </div>
          <div class="more">
            <img src="../../assets/images/marktingAddeb.svg" class="fs16 svg-icon"
                 style="width: 16px; height: 16px;">
          </div>
        </div>
      </template>
    </van-action-sheet>
    <!--  编辑删除  -->
    <van-action-sheet
        v-model="editShow"
        :actions="editActions"
        cancel-text="取消"
        close-on-click-action
        @cancel="editShow = false"
        @select="editOr"
    />
    <!--  新建  -->
    <van-action-sheet
        v-model="shareShow"
        cancel-text="取消"
        close-on-click-action
        @cancel="shareShow = false"
    >
      <template slot="default">
        <div class="share-icon">
          <div @click="forwardData">
            <img src="../../assets/images/marketSend.svg"/>
            转发
          </div>
          <!--          <div>-->
          <!--            <img src="../../assets/images/wechatMaterial.svg"/>-->
          <!--            微信-->
          <!--          </div>-->
          <div @click="forwardDatap" v-if="shareDataFile.type == '图片' || shareDataFile.type == '图文' || shareDataFile.type == '视频'  ">
            <img src="../../assets/images/marketFriend.svg"/>
            客户朋友圈
          </div>
          <div @click="copy" id="copy_text"
               　　　　　　ref="copy"
               　　　　　　　data-clipboard-action="copy"
               　　　　　　:data-clipboard-text="shareData.url">
            <img src="../../assets/images/marketCopy.svg"/>
            复制
          </div>
        </div>
      </template>
    </van-action-sheet>
  </div>
</template>

<script>
import {destroy, mediumGroup, mediumList} from "../../api/medium";
import {wechatMoments, forward} from '@/utils/wxCodeAuth'
import Clipboard from 'clipboard'
import {sendChatMessage, shareToExternalMoments} from "../../utils/wxCodeAuth";

export default {
  data() {
    return {
      // 文字
      active: 0,
      serchValue: '',
      message: '',
      // tbas切换
      tabsChose: 0,
      value1: 0,
      value2: 1,
      value3: '',
      option1: [
        {text: '全部分组', value: 0},
      ],
      option2: [
        {text: '所有人', value: 1},
        {text: '我自己', value: 2},
      ],
      option3: [
        {text: '上传时间优先', value: ''},
        {text: '发送次数优先', value: 1},
      ],
      // 新建操作
      shows: false,
      actions: [
        {name: '文字', subname: 1},
        {name: '图片', subname: 2},
        {name: '图文', subname: 3},
        {name: '音频', subname: 4},
        {name: '视频', subname: 5},
        {name: '小程序', subname: 6},
        {name: '文件', subname: 7}

      ],
      // 编辑操作
      editShow: false,
      editActions: [
        {name: '编辑'},
        {name: '删除', color: '#ee0a24'}
      ],
      // 分享操作
      shareShow: false,
      finished: false,
      loading: false,
      refreshing: false,
      perPage: 10000,
      listData: {
        text: [],
        img: [],
        imgText: [],
        vocie: [],
        video: [],
        applet: [],
        file: []
      },
      fileId: '',
      shareData: {
        title: '',
        url: ''
      },
      shareDataFile: {}

    }
  },
  watch: {},
  created() {
    this.getList()
  },
  methods: {
    // 轨迹搜索
    onSearch() {
      this.getList()
    },
    onClickLeft() {
      this.$router.back()
    },
    // 新建
    onSelect(e) {
      this.shows = false
      this.$router.push({
        path: '/medium/create',
        query: {
          type: e.subname
        }
      })
    },
    show() {
      this.shows = true
    },
    onRefresh(e) {
    },
    onLoad() {

    },
    group() {
      mediumGroup().then(res => {
        this.option1.push(...res.data.map(i => {
          return {
            text: i.name,
            value: i.id
          }
        }))
      })
    },
    editOr(e) {
      if (e.name == '编辑') {
        this.$router.push({
          path: '/medium/create',
          query: {
            id: this.fileId
          }
        })
      } else {
        this.detele()
      }
    },
    detele() {
      destroy({
        id: this.fileId
      }).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },

    forwardDatap() {
      this.shareShow = false
      let data = this.shareDataFile
      let shareData = {
      }
      if (data.type == '图片') {
        shareData.type = 2
        shareData.content = data.mediaId
      } else if (data.type == '图文') {
        shareData.type = 3
        shareData.content = data.content
      } else if (data.type == '视频') {
        shareData.type = 5
        shareData.content = data.mediaId
      } else if (data.type == '小程序') {
        shareData.type = 6
        shareData.content = data.content
      } else if (data.type == '文件' || data.type == '音频') {
        shareData.type = 7
        shareData.content = data.mediaId
      }
      shareToExternalMoments(data.content.title, shareData).then(res=>{
        this.$message.success('转发成功')
      })
    },
    forwardData() {
      this.shareShow = false
      let data = this.shareDataFile
      let shareData = {}
      if (data.type == '图片') {
        shareData.type = 2
        shareData.content = data.mediaId
      } else if (data.type == '图文') {
        shareData.type = 3
        shareData.content = data.content
      } else if (data.type == '视频') {
        shareData.type = 5
        shareData.content = data.mediaId
      } else if (data.type == '小程序') {
        shareData.type = 6
        shareData.content = data.content
      } else if (data.type == '文件' || data.type == '音频') {
        shareData.type = 7
        shareData.content = data.mediaId
      }
      console.log(shareData.content)
      sendChatMessage(shareData.type, shareData).then(res=>{
        this.$message.success('转发成功')
      })
    }
    ,
    copy()
    {
      this.shareShow = false
      var clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        this.$message.success('复制成功')

        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$message.error('复制失败')
        // 释放内存
        clipboard.destroy();
      });
    }
    ,
    details(i)
    {
      let url = ''
      switch (i.type) {
        case '文本':
          return
          break
        case '图片':
          url = i.content.imageFullPath
          break
        case '图文':
          url = i.content.imageLink
          break
        case '音频':
          url = i.content.voiceFullPath
          break
        case '视频':
          url = i.content.videoFullPath
          break
        case '小程序':
          url = i.content.page
          break
        case '文件':
          url = i.content.fileFullPath
          break
      }

      this.$router.push({
        path: '/medium/detail',
        query: {url}
      })
    }
    ,

    // 獲取數據
    getList()
    {
      this.group()
      mediumList({
        mediumGroupId: this.value1,
        creatorType: this.value2,
        order: this.value3,
        searchStr: this.serchValue || '',
        page: 1,
        perPage: this.perPage,
      }).then(res => {
        let listData = {
          text: [],
          img: [],
          imgText: [],
          vocie: [],
          video: [],
          applet: [],
          file: []
        }
        res.data.list.forEach(i => {
          switch (i.type) {
            case '文本':
              listData.text.push(i)
              break
            case '图片':
              listData.img.push(i)
              break
            case '图文':
              listData.imgText.push(i)
              break
            case '音频':
              listData.vocie.push(i)
              break
            case '视频':
              listData.video.push(i)
              break
            case '小程序':
              listData.applet.push(i)
              break
            case '文件':
              listData.file.push(i)
              break
          }
        })
        this.listData = listData
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  .title-serch {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .bet {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkMarkting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 18px 10px 18px;

    .info {
      .title {
        color: #000;
        margin-bottom: 5px;
      }

      .infotxt {
        color: #8c8c8c;
        line-height: 21px;
        flex-grow: 1;
      }

      .more {
        width: 16px;
        height: 16px;
        margin-right: 20px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .share-icon {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 10px;

    div {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
      color: #797979;
    }
  }

  .content {
    background: #f2f4f8;
    min-height: 92%;
    margin-top: -15px;
    //padding-top: 5px;
    img {
      width: 22px;
      height: 22px;
    }

    .van-hairline--bottom {
      &::after {
        border-bottom-width: 1px;
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border: 0 solid #f2f4f8;
        -webkit-transform: scale(.5);
        transform: scale(.5);
      }
    }

    .item {
      padding: 12px 16px;
      width: 100%;

      .title {
        width: 100%;
        font-size: 12px;

        div:nth-child(1) {
          color: #444;
          font-size: 12px;
        }

        img {
          width: 14px;
          height: 14px;
        }
      }

      .item-body {
        .body-item {
          background: #f4f5f7;
          padding: 18px 15px;
          margin-top: 10px;
          border-radius: 12px;
          font-size: 12px;
          color: #585757;

          div {
            margin-bottom: 5px;
          }

          div:nth-child(1) {
            color: #444;
            margin-bottom: 10px;

            span {
              background: #c277ff;
              color: #fff;
              padding: 2px 3px;
              border-radius: 5px;
              font-size: 12px;
              margin-right: 9px;
            }
          }

          div:last-child {
            margin-top: 10px;
            color: #939393;
          }
        }
      }

      .items {
        background: #fff;
        border-radius: 12px;
        padding: 13px 13px;
        margin-bottom: 10px;

        .title {
          display: flex;

          img {
            width: 60px;
            height: 60px;
          }

          .share {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10px;
            height: 50px;

            div:nth-child(1) {
              color: #444;
              font-weight: 530;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 254px;
            }

            div:nth-child(2) {
              color: #ccc;
              font-size: 12px;
              width: 80px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

          }
        }

        .share-button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          div:nth-child(1) {
            font-size: 12px;
            color: #9b9b9b;
          }

          div:nth-child(2) {
            font-size: 12px;
            background: #f0f4ff;
            color: #1890ff;
            padding: 1px 7px;
            border-radius: 15px;
            display: flex;
            align-items: center;

            img {
              width: 12px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
